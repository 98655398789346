import React, { useState, useEffect, useCallback, useRef } from "react";
import card1 from "./../../../images/batch1_1WONG_FINAL-EXTERIOR-PERSPECTIVE-1024x576 1.png";
import card2 from "./../../../images/batch1_11-768x576 1.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import next from "./../../../images/Group 15.svg";
import pre from "./../../../images/Group 14.svg";
const Construction = () => {
  const swiperRef = useRef(null);

  const [currentIndex, updateCurrentIndex] = useState(0);
  const params = {
    initialSlide: 2,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    spaceBetween: 30,
    loop: true,
    autoplay: true,
  };

  // Manipulate swiper from outside
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      updateIndex();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const updateIndex = useCallback(
    () => updateCurrentIndex(swiperRef.current.swiper.realIndex),
    []
  );
  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;

    if (swiperInstance) {
      swiperInstance.on("slideChange", updateIndex);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", updateIndex);
      }
    };
  }, [updateIndex, swiperRef]);
  return (
    <div>
      <div className="main-container pb-[115px] construction relative">
        <div className="text-center border-b border-[#009CA6]">
          <h2 className="section-title teko">CONSTRUCTION SERVICES</h2>
        </div>
        <div className="absolute flex gap-x-5 right-6 pt-7">
          <img src={pre} alt="" onClick={goPrev} className="cursor-pointer" />
          <img src={next} alt="" onClick={goNext} className="cursor-pointer" />
        </div>
        <div className="lg:pt-[116px] md:pt-[50px] pt-7 ">
          <Swiper
            spaceBetween={62}
            slidesPerView={1}
            pagination={true}
            modules={[Pagination, Navigation]}
            navigation={true}
            loop={true}
            loopFillGroupWithBlank={true}
            {...params}
            ref={swiperRef}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            className="mySwiper"
          >
            {ConstructionData.map(({ id, image, title }) => {
              return (
                <SwiperSlide
                  key={id}
                  className="max-w-[626px] w-full h-full min-h-[576px]"
                >
                  <div className="relative">
                    <div className="">
                      <img src={image} alt="" className="h-[576px]" />
                    </div>
                    <div className="absolute bottom-10 left-12">
                      <h1 className="slidecardbg 2xl:text-heading-40 md:text-[25px] text-[20px] font-bold px-3 py-4 monts">
                        ARCHITECTURAL DESIGN
                      </h1>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="text-center">
          <p className="font-medium roboto 2xl:text-[32px] lg:text-[25px] text-[20px] lg:mt-[65px] md:mt-10 mt-7">
            Belgian Iloilo Construction Corp is a construction corporation that
            provides various outstanding general works construction services
            such as roads, drainage, canals, warehouses, and buildings. We also
            take charge of land development, trucking services, and construction
            consultancy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Construction;
const ConstructionData = [
  {
    id: "1",
    image: card1,
    title: "",
  },
  {
    id: "2",
    image: card2,
    title: "",
  },
  {
    id: "3",
    image: card1,
    title: "",
  },
  {
    id: "4",
    image: card2,
    title: "",
  },
];
