import React from "react";

const Contact = () => {
  return (
    <div className="bg-[#D9D9D9] pt-[59px] 2xl:pb-[139px] pb-[59px]">
      <div className="main-conatiner mb-[60px] px-5">
        <div className="text-center 2xl:mb-[70px] mb-8">
          <h2 className="text-[#101820] text-[40px] uppercase 2xl:mb-[39px] mb-7 teko">
            CONTACT US
          </h2>
        </div>
        <div className="2xl:max-w-[606px] max-w-[500px] mx-auto">
          <form>
            <div className="w-full  ">
              <input
                placeholder="Full Name"
                type="name"
                className="contact-input-box border-inputs"
              />
            </div>
            <div className="w-full  ">
              <input
                placeholder="Company"
                type="name"
                className="contact-input-box"
              />
            </div>
            <div className="w-full  ">
              <input
                placeholder="Phone No"
                type="name"
                className="contact-input-box"
              />
            </div>
            <div className="w-full  ">
              <input
                placeholder="Contact"
                type="name"
                className="contact-input-box"
              />
            </div>
            <div className="w-full  ">
              <textarea
                rows="5"
                placeholder="Message"
                type="message"
                className="contact-input-box"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
