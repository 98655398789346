import React from "react";
import feat1 from "./../../../images/Commercial building Construction.webp";
import feat2 from "./../../../images/Demolition of building.webp";
import feat3 from "./../../../images/Construction Project Management.webp";

const Feature = () => {
  return (
    <div>
      <div className="main-container mb-[60px] pt-28">
        
        <div className="text-center">
          <h2 className="section-title teko">Our services</h2>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 place-items-center px-5">

        <div key="0" className="feature-card-shadow ">
                <div className="flex justify-center mb-[15px]">
                  <img src={feat1} alt="" />
                </div>
                <div className="text-center ">
                  <p className="roboto lg:text-[20px] text-[17px] font-medium">
                  <b>Building Construction </b><br/><br/> We pride ourselves of vast experience in the building industry where we have constructed residential buildings, commercial buildings and also institutional buildings.
                  </p>
                </div>
              </div>
              <div key="1" className="feature-card-shadow ">
                <div className="flex justify-center mb-[15px]">
                  <img src={feat2} alt="" />
                </div>
                <div className="text-center ">
                  <p className="roboto lg:text-[20px] text-[17px] font-medium">
                  <b>Demolition and removal</b><br/><br/> BICC is a specialist demolition contractor, from low rise houses to high rise buildings. We carry out all aspects of demolition.
                  </p>
                </div>
              </div>
              <div key="2" className="feature-card-shadow ">
                <div className="flex justify-center mb-[15px]">
                  <img src={feat3} alt="" />
                </div>
                <div className="text-center ">
                  <p className="roboto lg:text-[20px] text-[17px] font-medium">
                  <b> Construction Project Management </b> <br/><br/>BICC also offers a wide range of project management services which include but not limited to construction project planning & management, contract management, construction project supervisions and site inspections.
                  </p>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;