import React from "react";
import Feature from "./Feature";
import Construction from "./Construction";
import General from "./General";
import Contact from "./Contact";
import Aboutus from "./Aboutus";
import Cards2 from "./Cards2";
import ConstructionSlider from "./ConstructionSlider";
import Header from "./../../views/home/Header";

const index = () => {
  return (
    <div>
      <div id="home" >
        <Header />
      </div>
      <div id="services" >
        <Feature />
      </div>
      { /*<div id="services">
        <Construction />
      </div>
      <General />
      <ConstructionSlider />
  <Cards2 /> */ }
      <div id="aboutus">
        <Aboutus />
      </div>

      {/* <Secondslider/> */}
      <div id="contactus">
        <Contact />
      </div>
    </div>
  );
};

export default index;
