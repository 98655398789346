import React from "react";
import About from "./../../../images/Civil Engineering Structures and Roads Construction.webp";

const Aboutus = () => {
  return (
    <div>
      <div className="main-container py-[40px]">
        <div>
          <div className="text-center">
            <h2 className="section-title teko">ABOUT US</h2>
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 2xl:gap-[60px] gap-10 text-[#101820] roboto md:text-[32px] text-[20px] font-medium">
            <div>
              <div className="flex lg:justify-start justify-center">
                <img src={About} alt="" className="lg:mb-[35px] mb-5" />
              </div>
              <p className="lg:max-w-[545px]">
              <b>Our Vision </b><br/><br/>
               A regional market leader in provision of civil engineering and building construction services
              </p>
            </div>
            <div>
              <p className="lg:mb-[70px] md:mb-[40px] mb-5">
              <b>Our Mission</b><br/><br/>
              We aim to be a world class engineering Construction Company providing efficient affordable, sustainable, cost effective services of highest level of quality.
              </p>
              <p className="mb-[70px]">
              <b>Our Values</b><br/><br/>
              We believe in maintaining the highest standard of professionalism, integrity, creativity, positive attitude, delivery that meets and surpass expectations while offering prompt and lasting solutions that stand the test of time.              
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
