import React from 'react'
import Index from "./../components/views/home"
import Layout from "./../components/Layout"


const IndexPage = () => {
  return (
    <div>
      <Layout>
        <Index/>
      </Layout>
    </div>
  )
}

export default IndexPage