import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { IoLocationSharp, IoCall } from "react-icons/io5";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "gatsby";
import Logo from "./../../images/FooterLogo.png";

const Footer = () => {
  return (
    <div className="bg-[#101820] pt-6">
      <div className="bg-[#DDD9D7] py-[35px]">
        <div className="main-container">
          <div className="flex items-center lg:flex-nowrap flex-wrap justify-between gap-x-[128px]">
            <div className="mb-4">
              <img src={Logo} alt="" />
            </div>
            <div className="relative">
              <AnchorLink href="#contactus">
                <div className="2xl:absolute -bottom-6 right-0 bg-[#101820] md:py-4 py-1 px-[50px] flex justify-center teko text-[24px] rounded-[4px] md:max-w-[300px] w-full text-white">
                  Contact Us
                </div>
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container ">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 py-[48px] ">
          <div>
            <h5 className="footer-title">ABOUT US</h5>
            <ul className="flex flex-col  mt-[30px]">
              {links.map(({ id, text, url }) => {
                return (
                  <li key={id} className=" mb-[34px]">
                    <AnchorLink href={`#${url}`}>
                      <a className="capitalize  text-[16px] text-white">
                        {text}
                      </a>
                    </AnchorLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <h5 className="footer-title">FOLLOW US</h5>
            <ul className="flex gap-[26px]  my-[30px]">
                  <li key="0" className=" ">
                    <Link href="https://www.facebook.com/iloiloconstruction">
                      <div className="text-white text-[25px]"><FaFacebookF /></div>
                    </Link>
                  </li>
            </ul>
          </div>
          <div>
            {/* <h5 className="footer-title">SUBSCRIBE TO OUR NEWSLETTER</h5> */}

            {/* <div className=" border rounded-lg m relative md:max-w-[383px] max-w-[280px] p-1 w-full mt-[30px] min-h-[40px] mb-[30px] flex justify-between">
              <input
                type="email"
                placeholder="Enter your Email"
                className="bg-[#101820] w-full  rounded-xl text-white placeholder:text-[16px] px-4 focus:outline-none"
              />
              <button className="send-btn roboto ">Send</button>
            </div> */}

            <div className="flex text-white items-center text-[14px] gap-x-[14px] ">
              <IoLocationSharp />
              <p className="text-[16px] monts">
              Donato Pison Avenue, San Rafael, Mandurriao, Iloilo City, 5000
              </p>
            </div>
            <div className="flex text-white items-center text-[14px] gap-x-[14px]">
              <IoCall />
              <p className="text-[16px] monts">+63(0)917 568 60 30</p>
            </div>
          </div>
        </div>
        <div className="text-center py-[33px] border-t border-[#009CA6]">
          <p className="text-white text-[16px] roboto">
            Copyright © 2022 BiCC. All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

const links = [
  { id: 1, text: "Contact us", url: "contactus" },
  { id: 2, text: "About us", url: "aboutus" },
  { id: 3, text: "Services", url: "services" },
];
