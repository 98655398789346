import React, { useEffect, useState } from "react";

import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { AiOutlineSearch } from "react-icons/ai";
import { Link } from "gatsby";
import Logo from "./../../images/Logo.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
const Navbar = ({ backgroundColor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sticky, setSticky] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const handleSticky = () => {
    if (window.scrollY > 80) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleSticky);
  });
  return (
    <div className="main-container">
      <div className="w-full" style={{ backgroundColor: backgroundColor }}>
        <div className=" ">
          <div className="flex justify-between self-center items-center w-full md:py-[41px] py-6">
            <Link to={`/`}>
              <img src={Logo} alt="logo" className="" />
            </Link>
            <ul className=" hidden lg:flex gap-[78px] items-center self-center ">
              {links.map((link) => {
                const { id, text, url } = link;
                return (
                  <li key={id} className="flex self-center">
                    <AnchorLink href={`#${url}`}>
                      <p className="text-[28px]  teko text-white active:text-[#EC008C] hover:text-[#EC008C]  capitalize">
                        {text}
                      </p>
                    </AnchorLink>
                  </li>
                );
              })}
              <li>
                <Link to={"/"}>
                  <div className="text-[#EC008C] text-[30px]">
                    <AiOutlineSearch />
                  </div>
                </Link>
              </li>
            </ul>

            <div className="lg:hidden block ">
              <button
                className="text-[#EC008C] text-[42px]"
                onClick={toggleDrawer}
              >
                <HiOutlineMenuAlt3 />
              </button>
              <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="right"
                className=""
              >
                <div className="h-full w-full pt-6 mb-10 px-5 bg-[#101820] text-primary">
                  <Link to="/">
                    <img src={Logo} alt="logo" />
                  </Link>
                  <ul className="flex flex-col px-4 mt-8 ">
                    {links.map((a) => {
                      const { id, text, url } = a;
                      return (
                        <li key={id} className="pb-5">
                          <AnchorLink href={`#${url}`}>
                            <p className="text-[28px]  teko text-white active:text-[#EC008C] hover:text-[#EC008C]  capitalize ">
                              {text}
                            </p>
                          </AnchorLink>
                        </li>
                      );
                    })}
                    <li className="pt-5">
                      <Link to={"/"}>
                        <div className="text-[#EC008C] text-[30px]">
                          <AiOutlineSearch />
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Drawer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

const links = [
  { id: 1, text: "Home", url: "home" },
  { id: 2, text: "Services", url: "services" },
  { id: 3, text: "About us", url: "aboutus" },
  { id: 4, text: "Contact us", url: "contactus" },
];
