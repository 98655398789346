import React from "react";
import Navbar from "./../../Layout/Navbar";
import { IoIosArrowForward } from "react-icons/io";

import { Link } from "gatsby";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { ImLinkedin2 } from "react-icons/im";
import AnchorLink from "react-anchor-link-smooth-scroll";
const Header = () => {
  return (
    <div className="relative header-bg bg-blend-darken">
     
      <div className="absolute top-0 m-auto left-0 right-0">
        <Navbar />
      </div>
      <div className="main-container flex flex-col justify-center h-full">
        <div className="header-title my-[50px] py-8 px-8">
          <h1 className="monts pt-[35px] mb-3  text-white font-bold 2xl:text-[40px] md:text-[22px]  ">
          Bicc is your partner in construction based in Iloilo City. The company offers full range of construction engineering and project management services.
          </h1>
          {/* <div>
            <ul className="flex gap-[26px]  justify-center">
              {socialIcons.map(({ id, icon }) => {
                return (
                  <li key={id} className=" ">
                    <Link>
                      <div className="text-white text-[25px] border border-white rounded-[5px] p-2 mb-3">
                        {icon}
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div> */}
        </div>

        <AnchorLink href="#Feature">
          <a className="flex learn-more cursor-pointer z-[1000] rounded overflow-hidden  max-w-[208px] w-full teko text-[24px] min-h-[51px] items-center gap-5 px-5 text-white mb-56">
            Learn More
            <div className="text-white">
              <IoIosArrowForward />
            </div>
          </a>
        </AnchorLink>
      </div>
    </div>
  );
};

export default Header;
const socialIcons = [
  {
    id: 1,
    icon: <ImLinkedin2 />,
  },
  {
    id: 2,
    icon: <FaFacebookF />,
  },
  {
    id: 3,
    icon: <AiOutlineTwitter />,
  },
];
